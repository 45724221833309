.landingSearchDiv{
    margin-left: 5%;
}

/* .searchSection{
    height: 216px;
} */

.searchBox {
    display: flex;
    margin: 10px;
    /* margin-top: 50px; */
}

.searchDiv{
    display: flex;
    flex-direction: column;
}

 
.landingBrandname {
    color: #fff;
    font-size: 4.5rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    width: auto;
    font-family: Lato,sans-serif!important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    letter-spacing: 0.01em;
}

.brandNamePara{
    font-size: 1.3rem;
    margin-top: 20px;
    margin-left: 5px;
    color: white;
}

@media (max-width: 768px) {
    .landingBrandname {
        font-size: 3.5rem;
        line-height: 1.1;
    }
}
